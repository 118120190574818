<template>
<div style="margin-top:20px;">
  <!-- border: 1px solid #808080;border-radius: 5px; -->
<v-list-item :style="Editing ? '' : ''">
    <v-list-item-content v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.md || !Editing" style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action v-if="!Editing" style="font-weight: 300;">
       <v-list-item-avatar size="25" :style="Record[field.Name] ? 'background-color: '+Record[field.Name].hexa+';border: solid 1px #ff0000;' : 'background-color: #000000;border: solid 1px #ff0000;'">	
                    </v-list-item-avatar>
    </v-list-item-action>
    <v-list-item-content v-if="Editing" class="bootstrap-form-input">
      <!-- class="bootstrap-form-input" -->
       <v-menu			
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-list-item v-on="on">		
                    <v-list-item-avatar size="25" :style="Record[field.Name] ? 'background-color: '+Record[field.Name].hexa+';border: solid 1px #ff0000;' : 'background-color: #000000;border: solid 1px #ff0000;'">	
                    </v-list-item-avatar>
                  </v-list-item>
                  </template>
                   <v-color-picker v-model="Record[field.Name]"></v-color-picker>  	
                </v-menu>
           
    </v-list-item-content>
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {},
    data() {
        return {            
          DateMenu: false,
          TimeMenu: false,
        }
    },	
    computed:{
      FieldValue(){
        return this.Record && this.Record[this.field.id] ? 
        this.Record[this.field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
    }
}
</script>

<style>

</style>



