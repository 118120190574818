<template>
  <div>
    <!-- <component v-for="field in FilteredFields" :key="field.itemObjKey"
      :is="FieldComponent(field)":col="col"
      :System="System" :SystemEntities="SystemEntities" :field="field"
    /> -->
      <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="SaveRecord({Record: Record,Fields: col.FieldObjects})" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn> 
    <div v-for="field in FilteredFields" :key="field.itemObjKey">
      <SliderField :col="col" v-if="field.FieldType === 'SliderField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <ProgressField :col="col" v-if="field.FieldType === 'ProgressField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <CurrencyField :col="col" v-if="field.FieldType === 'CurrencyField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <LookupField :col="col" v-if="field.FieldType === 'LookupField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing" :RelatedObj="RelatedObj"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <SocialLookupField :col="col" v-if="field.FieldType === 'SocialLookupField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing" :RelatedObj="RelatedObj"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <URLLinkField :col="col" v-if="field.FieldType === 'URLLinkField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <RoutePathField :col="col" v-if="field.FieldType === 'RoutePathField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <ColorField :col="col" v-if="field.FieldType === 'ColorField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <OptionsField :col="col" v-if="field.FieldType === 'OptionsField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <ToggleField :col="col" v-if="field.FieldType === 'ToggleField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <TextField :col="col" v-if="field.FieldType === 'TextField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <BusinessHoursField :col="col" v-if="field.FieldType === 'BusinessHoursField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <DateField :col="col" v-if="field.FieldType === 'DateField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <EmailField :col="col" v-if="field.FieldType === 'EmailField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <TelephoneNumberField :col="col" v-if="field.FieldType === 'TelephoneNumberField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <NumberField :col="col" v-if="field.FieldType === 'NumberField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <PhysicalAddressField :col="col" v-if="field.FieldType === 'PhysicalAddressField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      <PostalAddressField :col="col" v-if="field.FieldType === 'PostalAddressField'"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="field"
      />
      
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import SliderField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/SliderField'
import ProgressField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/ProgressField'
import CurrencyField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/CurrencyField'
import LookupField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/LookupField'
import SocialLookupField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/SocialLookupField'
import URLLinkField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/URLLinkField'
import RoutePathField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/RoutePathField'

import ColorField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/ColorField'

import OptionsField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/OptionsField'
import ToggleField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/ToggleField'
import TextField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/TextField'
import BusinessHoursField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/BusinessHoursField'

import DateField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/DateField'
import EmailField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/EmailField'
import TelephoneNumberField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/TelephoneNumberField'
import NumberField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/NumberField'
import PhysicalAddressField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/PhysicalAddressField'
import PostalAddressField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/PostalAddressField'
export default {
    props: ['System','SystemEntities','col','Record','CanEdit','RelatedObj'],
    components: {
      SliderField,ProgressField,CurrencyField,LookupField,SocialLookupField,URLLinkField,RoutePathField,ColorField,OptionsField,ToggleField,TextField,BusinessHoursField,DateField,EmailField,TelephoneNumberField,NumberField,
      PhysicalAddressField,PostalAddressField
      },
    data() {
        return {
          Editing: false,
          Types: [
                'Sliders',
                'Progress Bars',
                'Currencies',
                'Links',
                'Toggles',
                'Options',
                'Standard',
                'Mixed'
            ]
        }
    },	
    computed:{
        FilteredFields(){
          return this.col.FieldObjects.filter(field => {
            return field
            //return this.RenderField(field)
          })
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      RenderField(field){
        if(field.IsConditionalRender){
          return this.ConditionalRender(field)
        }
        else{
          return field
          // return !field.CommonFieldType && field.Type !== 'Parent Field' && !field.ParentFieldObj || field.CommonFieldType && field.CommonFieldType !== 'Physical Address' && field.CommonFieldType !== 'Postal Address'
          // || field.Type === 'Parent Field' && field.ParentFieldObj && !field.ParentFieldObj.CommonFieldType|| field.Type === 'Parent Field' && field.ParentFieldObj
          // && field.ParentFieldObj.CommonFieldType && field.ParentFieldObj.CommonFieldType !== 'Physical Address' && field.ParentFieldObj.CommonFieldType !== 'Postal Address'
        }
      },
      ConditionalRender(field){
        if(field.RenderConditionType === 'Boolean Match'){
          return this.ConditionalBooleanRender(field)
        }
        else if(field.RenderConditionType === 'Option Set Match'){
          return this.ConditionalOptionsRender(field)
        }        
      },
      ConditionalOptionsRender(field){
        let options = field.ConditionalOptions
        //console.log(options)
        let recordoption = this.Record[field.ConditionalOptionField]
        //console.log('recordoption',recordoption,options)
        return recordoption && options.includes(recordoption.ID)
      },
      ConditionalBooleanRender(field){
        let render = true
          field.ConditionalBooleans.map(bool => {
            if(this.Record[bool.Field] !== bool.Value){
              render = false
            }
          })
        return render
      },
      SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },
      FieldComponent(field){
        return () => import(`@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/`+field.ComponentName+`.vue`);
      }
    }
}
</script>

<style>

</style>



