<template>
<div style="margin-top:20px;">
  <!-- border: 1px solid #808080;border-radius: 5px; -->
<v-list-item :style="Editing ? '' : ''">
    <v-list-item-content v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.md || !Editing" style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action v-if="!Editing" style="font-weight: 300;">
        {{FieldValue}}
    </v-list-item-action>
    <v-list-item-content v-if="Editing" class="bootstrap-form-input">
      <!-- class="bootstrap-form-input" -->
       <v-list>
                      <v-list-item>
                        Mon-Fri:
                      </v-list-item>
                      <v-list-item>
                       <v-menu 
                        v-model="WeekdayStartTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput"
                            v-model="Record[field.Name].WeekDays.Start"
                            :label="'Start Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekDays.Start"
                            @input="WeekdayStartTimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu><v-menu 
                        v-model="WeekdayEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput"
                            v-model="Record[field.Name].WeekDays.End"
                             :label="'End Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekDays.End"
                            @input="WeekdayEndTimeMenu = false"
                            type="month"
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu>
                      </v-list-item>                      
                      <v-list-item>
                        Sat-Sun:
                      </v-list-item>
                      <v-list-item>
                       <v-menu 
                        v-model="WeekendStartTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput"
                            v-model="Record[field.Name].WeekEnds.Start"
                            :label="'Start Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekEnds.Start"
                            @input="WeekendStartTimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu><v-menu 
                        v-model="WeekendEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput"
                            v-model="Record[field.Name].WeekEnds.End"
                             :label="'End Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].WeekEnds.End"
                            @input="WeekendEndTimeMenu = false"
                            type="month"
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu>
                      </v-list-item>
                      <v-list-item>
                        Publ Hol:
                      </v-list-item>
                      <v-list-item>
                       <v-menu 
                        v-model="PublicHolidayStartTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput"
                            v-model="Record[field.Name].PublicHolidays.Start"
                            :label="'Start Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].PublicHolidays.Start"
                            @input="PublicHolidayStartTimeMenu = false"
                            type="month" 
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu><v-menu 
                        v-model="PublicHolidayEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        
                        >
                        <template v-slot:activator="{ on }">
                            <v-text-field class="smallinput"
                            v-model="Record[field.Name].PublicHolidays.End"
                             :label="'End Time'"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            clearable
                            ></v-text-field>
                        </template>
                        <v-time-picker
                            v-model="Record[field.Name].PublicHolidays.End"
                            @input="PublicHolidayEndTimeMenu = false"
                            type="month"
                            width="290"
                            class="ml-4"
                        ></v-time-picker>
                        </v-menu>
                      </v-list-item>
                    </v-list>
    </v-list-item-content>
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {},
    data() {
        return {            
          DateMenu: false,
          TimeMenu: false,
          WeekdayStartTimeMenu: false,
          WeekdayEndTimeMenu: false,
          WeekendStartTimeMenu: false,
          WeekendEndTimeMenu: false,
          PublicHolidayStartTimeMenu: false,
          PublicHolidayEndTimeMenu: false,
        }
    },	
    computed:{
        BusinessHours(){
            let array = [
                {Label: 'Mon-Fri:',Value: '',Prop: 'WeekDays'},
                {Label: 'Sat-Sun:',Value: '',Prop: 'WeekEnds'},
                {Label: 'Public Holidays:',Value: '',Prop: 'PublicHolidays'}
            ]
            return array
            .filter(arritem => {
                return this.Record[this.field.id] && this.Record[this.field.id][arritem.Prop]
                    && this.Record[this.field.id][arritem.Prop].Start && this.Record[this.field.id][arritem.Prop].End
            }).map(arritem => {
                arritem.Value = this.Record[this.field.id][arritem.Prop].Start+' to '+this.Record[this.field.id][arritem.Prop].End
                return arritem
            })
        },
      FieldValue(){
        let string = '('
            this.BusinessHours.map((arritem,i) => {
                if(i === 0){
                    string = string+arritem.Label+' '+arritem.Value
                }
                else{
                  string = string+' || '+arritem.Label+' '+arritem.Value  
                }                
            })
            string = string+')'
            return string === '()' ? '' : string
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
    }
}
</script>

<style>

</style>



