<template>
<div>
<v-list-item>
    <v-list-item-content v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm && !$vuetify.breakpoint.md || !Editing" style="font-weight: 300;">
        {{field.DisplayName}}
    </v-list-item-content>
    <v-list-item-action style="font-weight: 300;" v-if="!Editing">
      {{FieldValue}}
    </v-list-item-action>
    <v-list-item-content style="font-weight: 300;" v-if="Editing" class="bootstrap-form-input">
     <v-text-field class="" :filled="field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation' || field.ReadOnly"
        :readonly="field.ReadOnly" outlined dense
        :rules="field.IsMandatory? [$store.state.formrules.numberfield,$store.state.formrules.required,MinRule,MaxRule] : [$store.state.formrules.numberfield,MinRule,MaxRule]" 
        v-model.number="Record[field.Name]" type="number" :label="field.DisplayName"></v-text-field>
    </v-list-item-content>
</v-list-item>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','col','Record','field','CanEdit','Editing'],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
      MinRule() {
        return () => (this.field.Min && this.Record[this.field.Name] && this.Record[this.field.Name] >= this.field.Min) || 
        (!this.field.Min) || 
      'Should be MIN of '+this.field.Min
      },
      MaxRule() {
        return () => (this.field.Max && this.Record[this.field.Name] && this.Record[this.field.Name] <= this.field.Max) || 
        (!this.field.Max) || 
      'Should be MAX of '+this.field.Max
      },
      FieldValue(){
        return this.Record && typeof this.Record[this.field.id] === 'number' ? 
        this.Record[this.field.id] : 
        0
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
    }
}
</script>

<style>

</style>



